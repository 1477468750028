import axios from "axios"
import Vue from "vue"
import moment from "moment"

const CallMainContainer = () => import(/* webpackChunkName: "CallMainContainer" */ "./CallView.vue")
const CallWrapUp = () => import(/* webpackChunkName: "CallWrapUp" */ "./CallViewSummary.vue")
const CallSetup = () => import(/* webpackChunkName: "CallSetup" */ "./CallSetup.vue")

// exports basic app infos required for other modules and CallViewItem_components
const baseUrl = "/call"
export const callWrapUpUrl = "/call/summary"

export const callMainApp = { path: "/conversation/:callId", component: CallMainContainer }

export default {
  name: "New Call",
  apiUrl: "/api/calls",
  route: {
    path: baseUrl,
    component: CallSetup
  },
  routes: [
    {
      path: `${callWrapUpUrl}/:callId`,
      component: CallWrapUp
    }
  ],
  icon: "fas fa-comments"
}

export const baoDelayService = {
  delayedOperationsArray: [],
  saving: false,
  methods: {
    getIdForItem (item, index) {
      return "Item" + index // + item.name.toLowerCase();
    },
    /**
     * Delays the execution of callback by the provided time (default = 1sec)
     *
     * This should lead to a more consistent behavior when saving CallItems to the backend, otherwise there is a
     * possibility of creating multiple call items for the same call and playbook item
     * */
    delayedOperation (index, callback, callbackData, time = 1000) {
      const doa = baoDelayService.delayedOperationsArray
      if (doa[index]) {
        clearTimeout(doa[index].timeout)
        delete (doa[index])
      }
      const callbackMethod = () => {
        if (doa[index]) delete (doa[index])
        return callback(callbackData)
      }
      let timeout = null
      const promise = new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          callbackMethod().then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        }, time)
      })
      doa[index] = { promise, timeout, callbackMethod }
      return promise
    },
    /**
     * Executes the callback function of the delayed operation with index immediately
     * */
    executeDelayedOperation (index) {
      // When executing this method for all the items available in a playbook(in configurator),
      // if the op-id for an item is not available then return a resolved promise.
      if (index in baoDelayService.delayedOperationsArray) {
        const operation = baoDelayService.delayedOperationsArray[index]
        clearTimeout(operation.timeout)
        return operation.callbackMethod()
      }
      return Promise.resolve()
    },
    /**
     * Executes all delayed operations immediately
     * */
    executeAllDelayedOperations () {
      return new Promise((resolve) => {
        const waitFor = []
        for (const i in baoDelayService.delayedOperationsArray) {
          waitFor.push(baoDelayService.methods.executeDelayedOperation(i))
        }
        Promise.all(waitFor).then(() => {
          resolve()
        })
      })
    },
    convertMillisecondsToHHMMSS (milliseconds) {
      // Get hours from milliseconds
      const hours = milliseconds / (1000 * 60 * 60)
      const absoluteHours = Math.floor(hours)
      const h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours

      // Get remainder from hours and convert to minutes
      const minutes = (hours - absoluteHours) * 60
      const absoluteMinutes = Math.floor(minutes)
      const m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes

      // Get remainder from minutes and convert to seconds
      const seconds = (minutes - absoluteMinutes) * 60
      const absoluteSeconds = Math.floor(seconds)
      const s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds
      return h + ":" + m + ":" + s
    }
  }
}

export const utils = {
  runningIndex: 0,
  /**
   * Removes the html tag from the string
   * */
  stripHtmlTags (html) {
    if (html) {
      html = html.replace(/<style([\s\S]*?)<\/style>/gi, "")
      html = html.replace(/<script([\s\S]*?)<\/script>/gi, "")
      html = html.replace(/<\/div>/ig, "")
      html = html.replace(/<\/li>/ig, "")
      html = html.replace(/<li>/ig, "")
      html = html.replace(/<\/ul>/ig, "")
      html = html.replace(/<\/p>/ig, "")
      html = html.replace(/<br\s*[\/]?>/gi, "")
      html = html.replace(/<[^>]+>/ig, "")
    }
    return html
  },
  getLoadedData (additionalDataChoice, item) {
    let loadedData = null
    let index = -1
    if (item.loadedData && item.loadedData.length > 0) {
      item.loadedData.forEach((adc, i) => {
        if (adc.selected_choice.id === additionalDataChoice.id) {
          loadedData = adc
          index = i
        }
      })
    }
    return { loadedData, index }
  },
  findActionType (actionType, answerActions) {
    return answerActions.find(item => item.type === actionType)
  },
  getCallItemDataToSave (item, callId) {
    /***
     * Returns an object based on the arguments passed
     * */
    const data = {
      talkscript: item.playbookId,
      call: callId,
      talkscript_item: item.id,
      note: item.notes,
      answers: item.selectedAnswers,
      duration: item.duration || 0
    }
    if (item.additional_data) data.additional_data = item.additional_data
    return data
  },
  getCallItemSaveRequest (item, data) {
    /***
     * Returns a method that executes the post/put axios request based on the callItem.
     * Also, updates the callItem attached to the item
     * */
    return () => {
      let method = "post"
      let url = "/api/callitems/"
      if (item.callItem) {
        method = "put"
        url += item.callItem.id
      }
      return axios[method](url, data).then((response) => {
        // Item has to be updated here so that the next request which is already in queue uses the
        // updated item to check if the request must be post or put
        Vue.set(item, "callItem", response.data)
        return response
      })
    }
  },
  getUniqueId () {
    /**
     * Returns a new unique id based on the current datetime and an incremental index
     * */
    utils.runningIndex += 1
    return "Item" + Date.now() + "-" + utils.runningIndex
  },
  convertFromUnixFormat (value, format) {
    return moment.unix(value / 1000).format(format)
  }
}

export const typeServices = {
  isText (type) {
    return typeServices.is(type, "text")
  },
  isTag (type) {
    return typeServices.is(type, "tag")
  },
  isFormGroup (type) {
    return typeServices.is(type, "form")
  },
  isNumber (type) {
    return typeServices.is(type, "number")
  },
  isTextArea (type) {
    return typeServices.is(type, "textarea")
  },
  isDateTime (type) {
    return typeServices.is(type, "datetime")
  },
  isDate (type) {
    return typeServices.is(type, "date")
  },
  isTime (type) {
    return typeServices.is(type, "time")
  },
  isSelect (type) {
    return typeServices.is(type, "checkbox") || typeServices.is(type, "select")
  },
  isLookup (type) {
    return typeServices.is(type, "lookup")
  },
  isSingleSelectBox (type) {
    return typeServices.is(type, "booleancheckbox")
  },
  canSelectMultiple (type) {
    return typeServices.is(type, "checkbox")
  },
  isRadio (type) {
    return typeServices.is(type, "radio")
  },
  onlyDate (type) {
    return typeServices.isDate(type) && !(typeServices.isDateTime(type) || typeServices.isTime(type))
  },
  onlyTime (type) {
    return typeServices.isTime(type) && !(typeServices.isDateTime(type) || typeServices.isDate(type))
  },
  datetimeFormat (type) {
    if (typeServices.onlyDate(type)) return "YYYY-MM-DD"
    if (typeServices.onlyTime(type)) return "HH:mm"
    return "YYYY-MM-DD HH:mm"
  },
  fieldTypeRecognised (type) {
    return typeServices.isText(type) || typeServices.isNumber(type) || typeServices.isTextArea(type) ||
      typeServices.isDateTime(type) || typeServices.isDate(type) || typeServices.isTime(type) ||
      typeServices.isSelect(type) || typeServices.isSingleSelectBox(type) || typeServices.canSelectMultiple(type) ||
      typeServices.isRadio(type) || typeServices.isTag(type) || typeServices.isFormGroup(type)
  },
  is (type, expected) {
    return type === expected
  },
  shouldUseDropdown (type) {
    return this.isSelect(type) || this.isRadio(type)
  },
  isCrmLink (type) {
    return typeServices.is(type, "crmlink")
  },

  /* ==========  question type items (not CRM items)  ======== */

  isMultiSelectQuestion (type) {
    return ["question", "rated_multiselect", "multi_select_dropdown"].includes(type)
  },
  isSingleSelectQuestion (type) {
    return ["single_select_radio", "single_select_dropdown"].includes(type)
  },
  isQuestion (type) {
    return typeServices.isMultiSelectQuestion(type) || typeServices.isSingleSelectQuestion(type)
  }

}
