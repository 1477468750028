import { callMainApp } from "./index"
import axios from "axios"
import { mapActions, mapGetters, mapMutations } from "vuex"
import appInfo from "@/apps/call/index"
import { checkIfParentDomainIsAllowed } from "./utils"

const CallSetupMixin = {
  name: "CallSetupMixin",
  data () {
    return {
      isCallLoading: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      defaultPlaybook: "playbook/getDefaultPlaybook",
      counterparts: "callSetUpStore/getCounterparts"
    })
  },
  methods: {
    ...mapMutations({
      setCounterparts: "callSetUpStore/setCounterparts",
      removeCounterpart: "callSetUpStore/removeCounterpart"
    }),
    ...mapActions({
      fetchDefaultPlaybook: "playbook/fetchDefaultPlaybook",
      getCounterpartsFromUrl: "callSetUpStore/getCounterpartsFromUrl"
    }),
    handleSwiftQuickStartMessage (event) {
      const quickStartKey = "quick_start_call"
      const messageData = event.data
      const isDomainAllowed = checkIfParentDomainIsAllowed(event.origin)
      if (isDomainAllowed) {
        if (messageData.type === quickStartKey) {
          this.handleQuickStartTriggered(messageData)
        }
      }
    },
    handleQuickStartTriggered (messageData) {
      // First we double-check that a call is not already on
      // This is a case we expect not to happen, but just as a second check
      if (this.$route.path.includes("/conversation")) return
      if (this.defaultPlaybook && !!this.counterparts.length) this.startCall(this.defaultPlaybook.id)
      else this.setUpCallFromUrl(true, messageData.counterpartParams)

      // This is to let SWIFT know that this was properly handled, so that
      // there's no duplicate command triggered
      this.messageBaoSwift({ type: "quickStartHandled" })
    },
    setUpCallFromUrl (functionTriggeredManually = false, counterpartParams) {
      if (!counterpartParams) counterpartParams = this.$route.query

      const getCounterpartsFromUrl = this.getCounterpartsFromUrl({ counterpartParams })
      const fetchDefaultPlaybook = this.fetchDefaultPlaybook()

      const promisesArray = [
        getCounterpartsFromUrl,
        fetchDefaultPlaybook
      ]
      Promise.all(promisesArray).then(() => {
        if (this.defaultPlaybook) {
          if (this.isApplicationInIFrame) {
            // For bao Swift, we don't check if there's a counterpart selected. Once, there's a default playbook,
            // we auto start the call with it
            if (functionTriggeredManually) this.startCall(this.defaultPlaybook.id)
            // This message to swift is happening because if the user clicks on `Quick start` from the extension, and this
            // page has not fully loaded yet, then the swift message will not be received by the page. So, we are sending a
            // message to swift, that the call setup has been loaded. It then checks if quick start was clicked by the user.
            // If yes, then it sends a message back to start the call.
            else this.messageBaoSwift({ type: "callSetupLoaded" })
          } else {
            if (this.counterparts.length) this.startCall(this.defaultPlaybook.id)
          }
        }
      })
    },
    createCall (callData) {
      return new Promise((resolve, reject) => {
        axios.post(appInfo.apiUrl, callData).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    startCall (playbookId, routeQuery={}) {
      const that = this
      if (that.isCallLoading) return
      that.isCallLoading = true
      const callData = {
        user: that.user.pk,
        counterparts: that.counterparts,
        talkscript: playbookId
      }
      const externalCallId = this.$route.query.external_call_id
      if (externalCallId) callData.external_call_id = externalCallId

      return this.createCall(callData).then(newCall => {
        that.setCounterparts([])
        that.pushOrReplaceRoute({ path: callMainApp.path.replace(":callId", newCall.id), query: routeQuery })
        that.isCallLoading = false
      },
      error => {
        that.isCallLoading = false
        that.error = error.response
      })
    }
  }
}

export default CallSetupMixin
