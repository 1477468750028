const CallList = () => import(/* webpackChunkName: "CallList" */ "./CallList.vue")
const CallFeedback = () => import(/* webpackChunkName: "CallFeedback" */ "./CallFeedback.vue")

// exports basic app infos required for other modules and CallViewItem_components
const baseUrl = "/calls"

export const callFeedbackView = {
  name: "Call Feedback",
  route: {
    path: `${baseUrl}/:id`,
    component: CallFeedback
  }
}

export default {
  name: "Calls",
  apiUrl: `/api${baseUrl}`,
  route: {
    path: baseUrl,
    component: CallList
  },
  routes: [
    callFeedbackView.route
  ]
}

function findLinkedField (item, questionId) {
  if (item.linked_field && item.linked_field.field_definition && item.linked_field.field_definition.length) {
    return item.linked_field.field_definition.find(item => item.id === questionId)
  }
  return null
}

function addAnswers (item, tabs, isFullText) {
  let result = ""

  const answers = item.answers
  const fieldLabels = new Set()

  for (let answer of answers) {
    const linkedField = findLinkedField(item, answer.question_id)

    if (linkedField) {
      answer = { ...answer, editedText: linkedField.fieldType === "lookup" ? JSON.parse(answer.text).name : answer.text }

      const fieldLabel = linkedField.label
      if (isFullText && !fieldLabels.has(fieldLabel)) {
        fieldLabels.add(fieldLabel)
        result += `${tabs}- ${fieldLabel}\n`
      }

      result += `${tabs}${tabs}- ${strip(answer.editedText)}`
    } else {
      result += `${tabs}- ${strip(answer.text)}${answer.has_importance ? ` - Importance: ${answer.importance}` : ""}`
    }

    result += "\n"
  }
  return result
}

function handleCallItem (item, tabs, isFullText) {
  let result = ""
  const hasAnswers = item.answers && item.answers.length > 0
  const hasNotes = !!item.note && item.note.length > 0

  if (hasAnswers) result += addAnswers(item, tabs, isFullText)
  if (hasNotes) result += strip(item.note)
  return result
}

// Function to convert HTML entity names to their text equivalents for text summary section
function decodeHTMLEntities (text) {
  const textArea = document.createElement("textarea")
  textArea.innerHTML = text
  return textArea.value
}

export const getPlainTextToCopy = (item, isFullText) => {
  let result = ""
  if (!item) {
    throw "Unable to format"
  }

  // Create text-based format of call flow
  if (isFullText && item.bao_values && item.bao_values.length > 0) {
    // handle bao value
    const baoValue = item.bao_values[0]
    result += "bao value: " + baoValue.value + "/" + baoValue.max_value + "\n"
  }

  for (const i in item.call_flow) {
    const tsi = item.call_flow[i]
    const callItem = tsi.call_item

    let skip = !callItem && !tsi.additional_data && !tsi.type
    // we don't add items that do not have any data stored
    const emptyItem = isEmptyItem(callItem) && !tsi.type

    skip = skip || emptyItem
    if (skip) continue

    if (isFullText && result.length > 0) result += "\n"
    if (tsi.type === "objection") result += "Objection: "
    if (tsi.type === "default") result += "Dynamic Playbook: "
    if (isFullText || tsi.type) result += tsi.name
    if (callItem) {
      if (isFullText) {
        result += callItem.bao_value ? " - " + callItem.bao_value : ""
        result += "\n"
      }
      result += handleCallItem(callItem, "\t", isFullText)
    } else {
      if (isFullText) result += "\n"
    }

    if (!result.endsWith("\n")) result += "\n"
  }

  // decode the html entities for plain text in text summary field
  result = decodeHTMLEntities(result)

  return result
}

export const isEmptyItem = (callItem) => {
  return callItem &&
    isUnansweredItem(callItem) &&
    isEmpty(callItem.note) &&
    (!callItem.loaded_additional_data || !callItem.loaded_additional_data.length)
}

export const isUnansweredItem = (callItem) => {
  return (!callItem.answers || !callItem.answers.length)
}

export const isEmpty = (s) => {
  if (!s || s.length === 0) return true
  s = s.replace(/<p>\s*<\/p>/gi, "")
  return s.length === 0
}

export const strip = (html) => {
  html = html.replace(/<style([\s\S]*?)<\/style>/gi, "")
  html = html.replace(/<script([\s\S]*?)<\/script>/gi, "")
  html = html.replace(/<\/div>/ig, "\n")
  html = html.replace(/<\/li>/ig, "\n")
  html = html.replace(/<li>/ig, "  *  ")
  html = html.replace(/<\/ul>/ig, "\n")
  html = html.replace(/<\/p>/ig, "\n")
  html = html.replace(/<br\s*[\/]?>/gi, "\n")
  html = html.replace(/<[^>]+>/ig, "")

  const replaceString1 = "<toreplace3>"
  const replaceString2 = "<toreplace2>"
  html = html.replace(/(\n\n\n)+/ig, replaceString1)
  html = html.replace(/(\n\n)/ig, replaceString2)
  html = html.replace(/<toreplace3>/ig, "\n\n")
  html = html.replace(/<toreplace2>/ig, "\n")
  return html
}
