<template lang="html">
  <router-view />
</template>

<script>
import CallSetupMixin from "@/apps/call/CallSetupMixin"

export default {
  name: "App",
  mixins: [CallSetupMixin],
  mounted () {
    // This event listener is to listen to events from the browser extension
    window.addEventListener("message", (event) => this.handleSwiftQuickStartMessage(event))
  },
  beforeDestroy () {
    window.removeEventListener("message", (event) => this.handleSwiftQuickStartMessage(event))
  }
}
</script>
